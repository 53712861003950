import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../components/Layout/Layout"

const NotFoundPage = () => {
  useEffect(() => {
    navigate("/")
  }, [])
  return (
    <Layout>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage
